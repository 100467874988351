<template>
    <div id="PrivacyPolicy">
        <section id="privacy-policy-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">個人情報保護方針</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="privacy-policy-title" class="title-section-style hide-mb">
            <h2 class="title f29">個人情報保護方針</h2>
        </section>
        <section id="privacy-policy-main" class="w70 serif w85-mb">
            <article
                v-for="(item, index) in privacyPolicyArticles"
                :key="'privacy-policy-article-' + index"
                class="policy-article-style"
            >
                <h2 v-if="item.title" class="f16 f16-mb">
                    <i class="icon-bar">▎</i>{{ item.title }}
                </h2>
                <p v-html="wrap(item.article)" class="f14 f12-mb"></p>
            </article>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getPrivacyPolicyData from '@/data/getPrivacyPolicyData'
export default {
    name: 'PrivacyPolicy',
    setup() {
        const { privacyPolicyArticles } = getPrivacyPolicyData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            privacyPolicyArticles,
        }
    },
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
    #privacy-policy-main {
        padding-bottom: 80px;
    }
}
@media screen and(max-width:767px) {
    #privacy-policy-main {
        padding: 30px 0;
    }
}
</style>
