export default function () {
    const privacyPolicyArticles = [
        {
            article:
                '大自然界は、個人情報保護の重要性に鑑み、大自然界WEBサイト（以下、「本サイト」といいます）において収集した個人情報を、個人情報保護法に基づいて以下の方針で適切に取り扱います。',
        },
        {
            title: '個人情報とは',
            article:
                '本サイトを通じて利用者の方（以下、「利用者」といいます）に提供を受けた、個人に関する住所、氏名、電話番号、Eメールアドレス等、特定の個人を識別できる情報を指します。 ',
        },
        {
            title: '個人情報の収集と利用目的について',
            article:
                '個人情報は、利用者が意図的に行う操作（入力フォームへの記入・送信等）によって収集する事を原則とします。個人情報を含むデータのやり取りを行う場合は暗号化通信（SSL）を使用し、大自然界WEBサイトと利用者ブラウザ間でのデータ保護を行っています。\n \
                大自然界が個人情報を取得する場合、法令の定めのある場合を除いて、ご本人の事前の同意を頂くことなく、予め明示した利用目的以外に使用する事はいたしません。大自然界では、利用目的などを偽って個人情報を収集する事はいたしません。また、不正な手段により個人情報を収集する事もいたしません。\n \
                大自然界では、収集している個人情報を以下の目的で利用します。その他、個別の利用目的がある場合には、その都度明記しております。\n \
                \n \
                <b class="bold f16">「基本セミナー申し込み」フォーム</b>\n \
                基本セミナー申し込み手続きのため、氏名とEメールアドレスをご入力いただいております。\n \
                \n \
                <b class="bold f16">「おつなぎ徳積みの理」」フォーム</b>\n \
                決済手続きのため、氏名、電話番号、Eメールアドレスをご入力いただいております。\n \
                \n \
                <b class="bold f16">「お問い合わせ」フォーム</b>\n \
                各種お問い合わせへの回答のため、氏名とEメールアドレスをご入力いただいております。\n \
                \n \
                <b class="bold f16"> アクセスログ</b>\n \
                本サイトは利用者の情報をアクセスログという形で記録しております。アクセスログは、利用者のドメイン名やIPアドレス、使用しているブラウザの種類、アクセス日時などが含まれます。個人の特定は行いません。\n \
                これらの情報は、本サイトの保守管理や利用状況に関する統計分析のために利用し、それ以外の目的では利用いたしません。\n \
                \n \
                <b class="bold f16"> クッキー（Cookie）</b>\n \
                本サイトでは、一部のコンテンツについて、情報の収集にクッキーを使用しています。クッキーは、利用者がサイトを訪れた際に、その利用者のコンピュータ内に記録されます。クッキーを個人の特定に用いる事はありません。\n \
                なお、お使いのブラウザの設定を変更することにより、クッキーの受け入れを拒否することができます。クッキーの受け入れを拒否した場合、当サイトで提供する機能が正常に利用できない場合もございますので、あらかじめご了承下さい。',
        },
        {
            title: '個人情報の管理について',
            article:
                '大自然界は収集した個人情報を厳重に保管・管理し、不正アクセス・紛失・破壊・毀損・改ざん・漏洩の防止等のため、必要な措置を講じます。保有する必要がなくなった個人情報は、確実かつ速やかに廃棄・消去します。',
        },
        {
            title: '個人情報の第三者への開示・提供について',
            article:
                '大自然界は、以下のいずれかに該当する場合を除き、本人の同意を得ることなく個人情報を第三者に開示する事はありません。\n \
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">法令に基づく場合</li>\
                    <li class="disc-list-li">人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得る事が困難である場合</li>\
                    <li class="disc-list-li">裁判所による開示を命じる判決もしくは命令を受けた場合</li>\
                    <li class="disc-list-li">警察などの公的機関から刑事訴訟法などの捜査権限を定める法令に基づき正式な照会を受けた場合</li>\
                </ul>',
        },
        {
            title: '外部サイトにおける個人情報の保護について',
            article:
                '本サイトへのリンクが設置された第三者のサイトおよび本サイトからリンクする第三者のサイトにおける個人情報の取り扱いについては、大自然界はいかなる場合においても責任を負いません。',
        },
        {
            title: '個人情報の照会・訂正・削除等について',
            article:
                '本サイトを通じて利用者より提供を受けた個人情報は、法令の定めるところにより、確認・修正・削除・利用停止などを申し出ることができます。お申し出は「お問い合わせ」フォームよりお願いいたします。\n \
                お申し出があった場合、お申し出者が本人である事を確認させていただいた上で、法令に基づき速やかに対応いたします。',
        },
        {
            title: '個人情報保護方針の変更について',
            article:
                '本文書は、2022年5月18日に改訂いたしました。大自然界では、個人情報保護を図るため、または法令その他の規範の変更に対応するために、個人情報保護方針の全部または一部を変更する場合があります。この場合には、当サイト上において変更した個人情報保護方針を掲載いたします。\n \
                大自然界で取り扱う個人情報に関するお問い合わせ・ご意見等がございましたら、「お問い合わせ」フォームよりご連絡下さい。',
        },
    ]
    return { privacyPolicyArticles }
}
